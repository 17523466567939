import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import EveryHero from "../global/every_hero";
import EverySVG from "../global/every_svg";

import imgFormsHero from "../../assets/images/imageHero04.jpeg";
import imgEmailForm from "../../assets/images/cat_laptop.png";

import maskPhoneOnChange from "../../util/maskPhoneOnChange";
import snackbarType from "../../enum/snackbar_type";
import templateType from "../../enum/email_template_type";
import FormsSchema from "../../schema/forms";

import useMessageService from "../../service/message_service";

const ContactUs = (props) => {
    let { showSnack } = props;
    const phoneField = useRef();
    const recaptchaRef = useRef();
    const messageService = useMessageService();

    const { contactUsForm } = FormsSchema;

    const {
        clearErrors,
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(contactUsForm)
    });

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const onPhoneChange = async (input) => {
        let { value } = input.target;
        maskPhoneOnChange(input, clearErrors);
        setValue(input.target.name, value.replace(/\D/g, ""));
    };

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            visitor_name: data.full_name,
            visitor_email: data.email,
            visitor_phone: data.phone,
            visitor_message: data.message,
            template_name: templateType.CONTACT_US,
            recaptcha_token: recaptchaToken
        };
            
        let res = await messageService.sendMessage(messageData);
        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, "Your message has been sent!");
        }
        
        setValue("full_name", null);
        setValue("email", null);
        setValue("phone", null);
        setValue("message", null);
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        phoneField.current.value = "";
        await recaptchaRef.current.reset();
    };

    return (
        <>
            <EveryHero image={imgFormsHero} 
                title={`Contact ${process.env.REACT_APP_DISPLAY_NAME}`}
                subtitle={"Questions or commments?"}
                text={<>Please contact {process.env.REACT_APP_DISPLAY_NAME}.</>}
            />

            <Container fluid style={{background: "#fff"}}>
                <Container className="my-5 max-width-xl">
                    <div className="form-title">
                        <h2>We'd Love to Hear From You!</h2>
                        <h3>Call us today at <a href="tel:208-365-5396">(208) 365-5396</a></h3>
                    </div>
                
                    <Row className="mx-3">
                        <Col xs={12} sm={12} lg={6}>
                            <Container className="mb-5">
                                <Row className="mb-3">
                                    <Col xs="auto" sm="auto">
                                        <EverySVG imageName="img-phone-icon" size="small" />
                                    </Col>
                                    <Col className="form-links">
                                        <h5>HOW</h5>
                                        <div className="mb-1"><a href="tel:208-365-5396">(208) 365-5396</a></div>
                                        <div><a href={`mailto: ${process.env.REACT_APP_DISPLAY_EMAIL}`}>{process.env.REACT_APP_DISPLAY_EMAIL}</a></div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="auto" sm="auto">
                                        <EverySVG imageName="img-clock-icon" size="small" />
                                    </Col>
                                    <Col>
                                        <h5>WHEN</h5>
                                        <Row className="mb-1">
                                            <Col xs={12} sm={12} md={4}>Monday - Friday</Col>
                                            <Col>7:00am - 6:00pm</Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs={12} sm={12} md={4}>Saturday</Col>
                                            <Col>8:00am - 12:00pm</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4}>Sunday</Col>
                                            <Col>Closed</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="auto" sm="auto">
                                        <EverySVG imageName="img-map-icon" size="small" />
                                    </Col>
                                    <Col>
                                        <h5>WHERE</h5>
                                        <div>2588 Waterwheel Rd</div>
                                        <div >Emmett, Idaho 83617</div>
                                    </Col>
                                
                                </Row>
                            
                            
                            </Container>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <Container className="mb-5">
                                <Row className="mb-3">
                                    <Col>
                                        <iframe
                                            title="where"
                                            width="100%"
                                            height="350"
                                            style={{border: "0"}}
                                            loading="lazy"
                                            allowFullScreen
                                            referrerPolicy="no-referrer-when-downgrade"
                                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API}&q=2588+Waterwheel+Rd,Emmett+ID+83617`}>
                                        </iframe>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    
                    </Row>
                </Container>
            </Container>
            <Container fluid style={{background: "#F2F5F8"}}>
                <Container className="my-5 max-width-xl">
                    <Row>
                        <Col className="align-self-center d-none d-lg-block">
                            <img src={imgEmailForm} style={{width: "100%", maxWidth: "30rem"}} alt=""/>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <Container className="max-width-sm my-5">
                                <h3>Drop Us A Line</h3>
                                <p>We normally respond within 2 business days</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="form-input">
                                        <Form.Label htmlFor="full_name">Full Name<span className="text-danger"> *</span></Form.Label>
                                        <Form.Control
                                            id="full_name"
                                            type="text"
                                            {...register("full_name")}
                                        />
                                        {errors.full_name && <span className="form-input-error text-danger">{errors.full_name.message}</span>}
                                    </Form.Group>
                                    <Form.Group className="form-input">
                                        <Form.Label htmlFor="email">Email Address<span className="text-danger"> *</span></Form.Label>
                                        <Form.Control
                                            id="email"
                                            type="text"
                                            {...register("email")}
                                        />
                                        {errors.email && <span className="form-input-error text-danger">{errors.email.message}</span>}
                                    </Form.Group>
                                    <Form.Group className="form-input">
                                        <Form.Label htmlFor="phone">Phone Number</Form.Label>
                                        <Form.Control type="hidden" {...register("phone")} />
                                        <Form.Control
                                            type="text"
                                            placeholder="(999) 999-9999"
                                            name="phone"
                                            ref={phoneField}
                                            onChange={onPhoneChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-input mb-3">
                                        <Form.Label htmlFor="message">Message Text</Form.Label>
                                        <Form.Control  as="textarea" rows={5} 
                                            id="message"
                                            type="text"
                                            {...register("message")}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="hidden"
                                            {...register("recaptchaToken")}
                                            value={recaptchaToken}
                                        />
                                        <div className="mb-3">
                                            <ReCAPTCHA
                                                sitekey={reCaptchaSiteKey}
                                                onChange={handleToken}
                                                onExpired={handleTokenExpired}
                                                ref={recaptchaRef}
                                            />
                                        </div>
                                    </Form.Group>
                                    <div className="disclaimer">
                                    * Required <br />
                                        {process.env.REACT_APP_DISPLAY_NAME} never sells or shares your information
                                    </div>
                                    <Button variant="primary" type="submit" disabled={submitIsDisabled}>
                                    SUBMIT
                                    </Button>
                                </Form>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default ContactUs;