import apiURI from "../enum/api-uri";
import method from "../enum/method";
import useApi from "../hooks/use-api";
import uiResponse from "../hooks/use-uiResponse";

import formatFormatData from "../util/formatFormData";

const EVERYDVM_TOKEN = "everydvm_token";

const useMessageService = () => {
    const api = useApi();
    const myToken = localStorage.getItem(EVERYDVM_TOKEN);

    const sendMessage = async messageData => {

        // add recipient environment variables to the messageData
        messageData.recipient = process.env.REACT_APP_SENDGRID_TO_EMAIL;
        messageData.recipient_name = process.env.REACT_APP_SENDGRID_TO_NAME;
        messageData.recipient_org_name = process.env.REACT_APP_SENDGRID_TO_ORG_NAME;

        const templateName = messageData.template_name;

        const formattedData = formatFormatData(messageData, templateName);

        try {
            const res = await api.request({
                method: method.POST,
                uri: apiURI.AUTH,
                route: "/v1/messages",
                data: formattedData,
                token: myToken
            });

            return res;

        } catch (e) {
            return uiResponse(false, {
                error: true,
                exception: e,
                errorMessage: e.message
            });
        }
    };

    return {
        sendMessage
    };

};

export default useMessageService;