import axios from "axios";

export const doResDataCheck = (res) => {
    if (!res || !res?.error || res?.data) {
        throw new Error(JSON.stringify(res?.message || res?.data));
    }
};

// Create a single axios instance with the base URL from environment variables
const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json"
    }
});

// Response interceptor for handling API responses and errors
axiosInstance.interceptors.response.use(
    response => {
        const { data } = response;
        if (data.error) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                message: data.message,
                status: data.status,
                error: true
            });
        }
        return data;
    },
    (error) => {
        const errorResponse = {
            success: false,
            error: {
                message: error.response ? error.response.data.message : error.message,
                status: error.response ? error.response.status : null
            }
        };
        return Promise.reject(errorResponse);
    }
);

const useApi = () => {
    const request = async ({ method, uri, route, data, token, headers = {}, isUpload = false }) => {
        if (token) {
            headers.Authorization = `Bearer ${JSON.parse(token).access_token}`;
        }
        if (isUpload) {
            headers["Content-Type"] = "multipart/form-data";
        }
        try {
            const response = await axiosInstance({
                method,
                url: `${uri}${route}`,
                data,
                headers
            });
            return response;
        } catch (error) {
            console.error("API request failed:", error);
            throw error;
        }
    };

    return { request };
};

export default useApi;