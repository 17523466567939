import React, { useMemo, useRef, useState } from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";

import AppContextProvider from "../context/app-context";

import "./app.scss";

import ScrollToTop from "../mod/scroll_to_top";

import Header from "./header/header";
import Main from "./main/main";
import Services from "./services/services";
import AboutUs from "./about/about_us";
import ContactUs from "./forms/contact_us";
import NewPatient from "./forms/new_patient";
import RequestAppointment from "./forms/request_appointment";
import Scratchpay from "./resources/scratchpay";
import Footer from "./footer/footer";

import SnackbarToast from "./global/snackbar_toast";

/* --- Services Pages --- */
import Equine from "./services/equine";
import Ruminant from "./services/ruminant";
import SmallAnimal from "./services/small-animal";
import PuppyPackage from "./services/puppy-package";
import KittenPackage from "./services/kitten-package";


function App() {

    let snackType = useRef();
    let snackMessage = useRef();
    let [showSnackbarToast, setShowSnackbarToast] = useState(false);
    const [googleLoaded, setGoogleLoaded] = useState(false);

    const showSnack = (type, message) => {
        snackType.current = type;
        snackMessage.current = message;
        setShowSnackbarToast(true);
    };

    const hideSnack = () => {
        setShowSnackbarToast(false);
    };

    const googleLibraries = useMemo(() => ["places"], []);

    // Memoize LoadScript to prevent re-renders
    const GoogleMapsLoader = useMemo(() => (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
            libraries={googleLibraries}
            onLoad={() => {
                // console.log("Google Maps API loaded"); // Debug log
                setGoogleLoaded(true);
            }}
        />
    ), []);

    return (
        <>
            {GoogleMapsLoader}
            <AppContextProvider>
                <BrowserRouter>
                    <ScrollToTop>
                        <Header />
                        <Routes>
                            <Route exact path='/' element={<Main showSnack={showSnack} />} />
                            <Route exact path='/services' element={<Services showSnack={showSnack} />} />
                            <Route exact path='/about-us' element={<AboutUs showSnack={showSnack} />} />
                            <Route exact path='/contact-us' element={<ContactUs showSnack={showSnack} />} />
                            <Route exact path='/new-patient' element={<NewPatient showSnack={showSnack} googleLoaded={googleLoaded} />} />
                            <Route exact path='/request-appointment' element={<RequestAppointment showSnack={showSnack} />} />
                            <Route exact path='/financing/scratchpay' element={<Scratchpay showSnack={showSnack} />} />
                            <Route exact path='/services/equine' element={<Equine showSnack={showSnack} />} />
                            <Route exact path='/services/ruminant' element={<Ruminant showSnack={showSnack} />} />
                            <Route exact path='/services/small-animal' element={<SmallAnimal showSnack={showSnack} />} />
                            <Route exact path='/services/puppy-package' element={<PuppyPackage showSnack={showSnack} />} />
                            <Route exact path='/services/kitten-package' element={<KittenPackage showSnack={showSnack} />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                        {showSnackbarToast &&
                            <SnackbarToast
                                toast={snackType.current}
                                message={snackMessage.current}
                                onClose={hideSnack}
                            />
                        }
                        <Footer />
                        {/* <CookieBanner /> */}
                    </ScrollToTop>
                </BrowserRouter>
            </AppContextProvider>
        </>
    );
}

export default App;